<template>
	<div class="card my-3">
		<div class="card-header white"><h6>批量冻结员工</h6></div>
		<div class="card-body b-b bg-light">
			<div class="stepper sw-main sw-theme-default" data-options="{
						 &quot;transitionEffect&quot;:&quot;fade&quot;

						 }">
				<ul class="nav step-anchor nav-tabs">
					<li :class="['nav-item',{'active': step == 11,'done': step > 11}]"><a href="#step-11" class="nav-link">第一步<br><small>上传：选择EXCEL文件，上传</small></a></li>
					<li :class="['nav-item',{'active': step == 22,'done': step > 22}]"><a href="#step-22" class="nav-link">第二步<br><small>导入：预览EXCEL，校验员工资料，确认导入</small></a></li>
					<li :class="['nav-item',{'active': step == 33}]"><a href="#step-33" class="nav-link">第三步<br><small>导入结果：显示导入结果</small></a></li>
				</ul>
				<div class="card no-b shadow sw-container tab-content" style="min-height: 212px;">
					<div id="step-11" class="card-body p-5 tab-pane step-content" :style="{display:(step==11?'block':'none')}">
						<div class="col-md-6 offset-md-3">
							<div role="alert" class="alert alert-warning"><strong>提醒：</strong> 
							导入前，请确保员工存在，且已激活，处于可用状态。
							</div>
							<div class="form-group">
								<label>选择EXCEL</label>
								<!-- <input type="file" class="form-control"> -->
								<input class="form-control" name="file" type="file" @change="getFile" />
								<div class="p-2"><a href="/static/staff-template.xlsx">模板下载</a></div>
							</div>
							<!-- <a href="#step-22" class="btn btn-primary mb-3 btn-lg">下一步</a> -->
							<button @click.stop="uploadStaffs" :disabled="disabled" type="button" class="btn btn-primary"> 上传预览 </button>
						</div>
					</div>
					<div id="step-22" class="card-body p-5 tab-pane step-content" :style="{display:(step==22?'block':'none')}">
						<div class="">
							<div>
								<h5>共：{{totalCount}}行，正确：{{count}} 行，错误 {{errCount}} 行</h5>
								<hr/>
							</div>
							
							<!-- <div>
								<h5 class="font-weight-bold">错误提示</h5>
								<ul>
								  <li class="text-red" v-bind:key="index" v-for="(item,index) in info">{{item}}</li>
								</ul>
							</div> -->
							
							
							<h5 class="font-weight-bold">错误行</h5>
							<div class="card r-0 shadow">
							<div class="table-responsive">
								<div>
									<table class="table table-striped table-hover r-0  mb-0">
										<thead>
											<tr class="no-b">
												<th>
													<!-- <div class="custom-control custom-checkbox">
														<input v-model="check_all" @click.stop="checkAll" type="checkbox"  class="custom-control-input" id="checkedAll"><label class="custom-control-label" for="checkedAll"></label>
													</div> -->
													<div class="d-none d-lg-block">行号</div>
												</th>
												<th>
													<div class="d-none d-lg-block">部门</div>
												</th>
												<th>
													<div class="d-none d-lg-block">姓名</div>
												</th>
												<th>
													<div class="d-none d-lg-block">工号</div>
												</th>
												<th>
													<div class="d-none d-lg-block">手机</div>
												</th>
												<th>
													<div class="d-none d-lg-block">错误原因</div>
												</th>
											</tr>
										</thead>
								
										<tbody>
											<tr v-bind:key="index" v-for="(item,index) in errCvoList">
												<td>
													<!-- <label class="custom-control custom-checkbox">
														<input v-model="checkedIds" :value="item.id" :id="index" :disabled="item.enabled == -1" class="custom-control-input" type="checkbox"><label class="custom-control-label" :for="index"></label>
													</label> -->
													{{item.line_number}}
												</td>
												<td>
													<div class="d-none d-lg-block">{{item.sub_dept_name}}</div>
												</td>
												<td>
													<div class="d-none d-lg-block">{{item.employee_name}}</div>
												</td>
												<td>
													<div class="d-none d-lg-block">{{item.employee_no}}</div>
												</td>
												<td>
													<div class="d-none d-lg-block">{{item.employee_mobile}}</div>
												</td>
												<td>
													<div class="d-none d-lg-block text-danger">{{item.reason}}</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							</div>
							<hr/>
							
							<!-- <div class="form-group mt-3">
								<label class="font-weight-bold text-primary">覆盖已存在的员工{{overlay_flag}}</label>
								<div class="material-switch">
									<input v-model="overlay_flag" id="someSwitchOptionInfo" name="someSwitchOption001" type="checkbox">
									<label for="someSwitchOptionInfo" class="bg-danger"></label>
								</div>
							</div> -->
							
							<div class="btn-group mt-2 sw-btn-group">
							<button @click.stop="back" type="button" class="btn btn-light">上一步</button>
							<button @click.stop="saveStaffs" :disabled="disabled" type="button" class="btn btn-primary"> 导入正确行 </button>
							</div>
						</div>
					</div>
					
					<div id="step-33" class="card-body p-5 tab-pane step-content" :style="{display:(step==33?'block':'none')}">
						
						<div class="col-md-8 offset-md-2">
							<h4>提交成功，请稍候在员工列表查查导入结果 </h4>
							<h5>
								共处理 {{total_count}} 个，冻结 {{result_count}} 个；
							</h5>
							<h6>实际冻结请稍候查询员工列表。</h6>
							<div class="mt-3">
								<button @click.stop="goFirst" :disabled="disabled" type="button" class="btn btn-primary"> 继续导入 </button>
							</div>
						</div>
						
						</div>
				<!-- <div class="btn-toolbar sw-toolbar sw-toolbar-bottom justify-content-end">
					<div class="btn-group mr-2 sw-btn-group" role="group">
						<button class="btn btn-secondary sw-btn-prev disabled" type="button">Previous</button>
						<button class="btn btn-secondary sw-btn-next" type="button">Next</button>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	module.exports = {
		data(){
			return {
				title: '导入员工',
				
				token: '',
				outlet_id: 0,
				outlet_name:'',
				user_name: '',
				outlet_level: 0,
				
				setting: {},
				outletlist: [],

				disabled: false,
				
				file: null,
				step: 11,
				
				count: 0,
				errCount: 0,
				errCvoList: [],
				info: [],
				rpCount: 0,
				totalCount: 0,
				
				overlay_flag: false,
				
				total_count: 0,
				otther_count: 0,
				result_count: 0,
				new_count: 0,
				exist_count: 0,
				
				////
				dept_id: '',
			}
		},
		
		
		created: function() {
			console.log('--- created --- ')
			let self = this;
			
			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;
			
			//2.取得店铺信息
			let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			self.dept_id = lsLoginData.dept_id;
			
			//创建员工页返回的 outlet_id
			let selectedOutletId = this.$route.params.outlet_id;
			if(selectedOutletId){
				self.outlet_id = selectedOutletId;
			}
			console.log('--- self.outlet_id @ importstaffs.vue -> ',self.outlet_id)
			console.log('--- $parent.outlet_id  -> ', this.$parent.outlet_id)
			
			//给父组件传值,修正navbar的 outlet_id >>使用 localstorage 或 vuex state
			// if(this.$parent.outlet_id != self.outlet_id){
			// 	this.$parent.outlet_id = self.outlet_id;
			// }
			
		},
		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;
			
			//1.检查登录
			// let token = localStorage.getItem('TOKEN');
			if (!self.token) {
				console.log('--- mounted jump  --- ')
				location.href = 'index.html';
				return;
			}
			
			// self.init(()=>{
			// 	self.initData();
			// });
			
		},
		methods: {
			
			checkAll(){
				let self = this;
				self.check_all = !self.check_all;
				console.log('--- check all ---',self.check_all)
				
				if(self.check_all){
					for(var i=0; i<self.stafflist.length; i++){
						if(self.stafflist[i].enabled == -1) continue;
						self.checkedIds.push(self.stafflist[i].id);
					}
				}
				else{
					self.checkedIds = [];
				}
			},
			
			back(){
			  let self = this;
			  self.step = self.step - 11;
			},
			
			goFirst(){
				let self = this;
				self.step = 11;
			},
			
			getFile(e) {
			  let self = this;
			  self.file = e.target.files[0];
			  console.log('=== file ->',self.file);
		    },
			
			importStaffReady(){
				let self = this;
				
				$('#iModal').modal('show');
			},
			
			uploadStaffs(){
				let self = this;
				
				let formData = new FormData();
				formData.append('token', self.token);
				formData.append('dept_id', self.dept_id);
				formData.append('file', self.file);
			
				let config = {
				  headers: {
					'Content-Type': 'multipart/form-data'
				  }
				}
				
				self.axios.post(self.GLOBAL.baseURI + 'uploadFreezeStaffs', formData, config)
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					
					self.disabled = false;
					
					if (data.code == 200) {
						
						if(data.data.return_code == 'success'){
							self.step = 22;
								
							self.count = data.data.count;
							self.errCount = data.data.errCount;
							self.errCvoList = data.data.errCvoList;
							self.info = data.data.info;
							self.rpCount = data.data.rpCount;
							self.totalCount = data.data.totalCount;
						}
						else{
							alert(data.data.return_msg);
						}
					}
					else if (data.code == 101) {
						//登录超时
						location.href = "index.html";
					}
					else{
						alert(data.message)
					}
				});
			},
			
			
			saveStaffs(){
				let self = this;
				
				let postData = {
					token: self.token,
					dept_id: self.dept_id,
					overlay_flag: self.overlay_flag?1:0,
				}
				
				self.axios.post(self.GLOBAL.baseURI + 'importFreezeStaffs', postData)
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					
					self.disabled = false;
					
					if (data.code == 200) {
						self.step = 33;
							
						self.total_count = data.data.total_count;
						self.result_count = data.data.result_count;
						self.errCvoList = [];
						self.info = [];
						// self.otther_count = data.data.otther_count;
						// self.new_count = data.data.new_count;
						// self.exist_count = data.data.exist_count;
					}
					else{
						alert(data.message)
					}
				});
			},
			
			
			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;
			
				self.init(()=>{
					self.initData();
					self.group_id = 0;
				});
			},
		},
	}
	
</script>

<style>
</style>
